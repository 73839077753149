import Vue from "vue";
import vueDebounce from "vue-debounce";
// Listening to multiple events

// Setting a default timer This is set to '300ms' if not specified
Vue.use(vueDebounce, {
  defaultTime: "1200ms",
  lock: false,
  listenTo: "input",
  fireOnEmpty: false,
  cancelonempty: true
});
