import Vue from "vue";
import store from "./core/services/store";
import App from "./App";
import wb from "./registerServiceWorker";
import router from "./router";
// Use Bootstrap
import "./core/plugins/bootstrap";
import "./core/plugins/globalComponents";
import "./core/plugins/debounce";
import "./core/plugins/vue-meta";
import "./core/plugins/custom-google-autocomplete";
import fire from "./core/plugins/firestore";
import detector from "./core/plugins/detector";
// import { VERIFY_AUTH } from "./core/services/store/auth.module";
import "./core/services/api.service";

Vue.config.productionTip = false;
Vue.prototype.$workbox = wb;
Vue.prototype.$firebase = fire;
Vue.prototype.$detector = detector;
// router.beforeEach((to, from, next) => {
//   // Ensure we checked auth before each page load.
//   Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
// });
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
