/* eslint-disable no-param-reassign */
// ACTION TYPES

// MUTATION TYPES
// STATE
const state = {};
// GETTERS
const getters = {};
// ACTIONS
const actions = {};
// MUTATIONS
const mutations = {};
export default {
  state,
  actions,
  mutations,
  getters
};
