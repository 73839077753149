/* eslint-disable no-param-reassign */
// ACTION TYPES
export const GET_WINDOW_WIDTH = "get_window_width";
export const GET_DEVICE_TYPE = "get_device_type";
// MUTATION TYPES
export const SET_WINDOW_WIDTH = "set_window_width";
export const SET_DEVICE_TYPE = "set_device_type";
// STATE
const state = {
  windowWidth: window.innerWidth,
  device: ""
};
// GETTERS
const getters = {
  getWindowWidth(currentState) {
    return currentState.windowWidth;
  },
  getDeviceType(currentState) {
    return currentState.device;
  }
};
// ACTIONS
const actions = {
  [GET_WINDOW_WIDTH](context) {
    const width = window.innerWidth;
    context.commit(SET_WINDOW_WIDTH, width);
  },
  [GET_DEVICE_TYPE](context) {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      context.commit(SET_DEVICE_TYPE, "tablet");
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      context.commit(SET_DEVICE_TYPE, "mobile");
      return "mobile";
    }
    context.commit(SET_DEVICE_TYPE, "desktop");
    return "desktop";
  }
};

// MUTATIONS
const mutations = {
  [SET_WINDOW_WIDTH](currentState, width) {
    currentState.windowWidth = width;
  },
  [SET_DEVICE_TYPE](currentState, device) {
    currentState.device = device;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
