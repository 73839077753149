/* eslint-disable no-param-reassign */
// ACTION TYPES
export const GET_SIDEBAR_STATUS = "get_sidebar_status";
export const CHANGE_SIDEBAR_STATUS = "change_sidebar_status";
// MUTATION TYPES
export const SET_SIDEBAR_STATUS = "set_sidebar_status";

// STATE
const state = {
  status: "open" // open,closed,collapsed
};
// GETTERS
const getters = {
  getSidebarStatus(currentState) {
    return currentState.status;
  }
};
// ACTIONS
const actions = {
  [GET_SIDEBAR_STATUS]() {
    return new Promise(resolve => {
      resolve(this.state.status);
    });
  },
  [CHANGE_SIDEBAR_STATUS](context, data) {
    return new Promise(resolve => {
      context.commit(SET_SIDEBAR_STATUS, data);
      resolve(this.state.status);
    });
  }
};

// MUTATIONS
const mutations = {
  [SET_SIDEBAR_STATUS](currentState, status) {
    currentState.status = status;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
