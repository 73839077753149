/* eslint-disable import/no-mutable-exports */
import axios from "axios";

function Event(name) {
  this.name = name;
  this.callbacks = [];
}
Event.prototype.registerCallback = function registerCallback(callback) {
  this.callbacks.push(callback);
};
function Reactor() {
  this.events = {};
}

Reactor.prototype.registerEvent = function registerEvent(eventName) {
  const event = new Event(eventName);
  this.events[eventName] = event;
};

Reactor.prototype.dispatchEvent = function dispatchEvent(eventName, eventArgs) {
  this.events[eventName].callbacks.forEach(function cb(callback) {
    callback(eventArgs);
  });
};

Reactor.prototype.addEventListener = function addEventListener(
  eventName,
  callback
) {
  this.events[eventName].registerCallback(callback);
};
class Detector {
  constructor() {
    this.onlineState = true;
    this.createEvents();
    this.sendRequest();
  }

  loop() {
    setTimeout(() => {
      this.sendRequest();
    }, 5000);
  }

  sendRequest = () => {
    return new Promise(resolve => {
      const url = process.env.VUE_APP_API_URL_PK;
      axios
        .get(url)
        .then(() => {
          if (!this.onlineState) {
            this.onlineState = true;
            this.reactor.dispatchEvent("app-connect-change", true);
          }
          this.loop();
          resolve(true);
        })
        .catch(() => {
          if (this.onlineState) {
            this.onlineState = false;
            this.reactor.dispatchEvent("app-connect-change", false);
          }
          this.loop();
          resolve(true);
        });
    });
  };

  createEvents = () => {
    this.reactor = new Reactor();
    this.reactor.registerEvent("app-connect-change");
  };

  getState = () => {
    return this.onlineState;
  };
}

// eslint-disable-next-line no-new
const detector = new Detector();

export default detector;
