import Vue from "vue";
import Vuex from "vuex";

// import createPersistedState from "vuex-persistedstate";
import auth from "./auth.module";
import orders from "./orders.module";
import appWindow from "./media-queries.module";
import sidebar from "./sidebar.module";
import general from "./general.module";
import riders from "./riders.module";
import payments from "./payments.module";
// import { getToken } from "../jwt.service";
import regions from "./region.module";
import invoices from "./invoices.module";
// Load VueX
Vue.use(Vuex);

// Create Store
export default new Vuex.Store({
  modules: {
    auth,
    orders,
    riders,
    appWindow,
    sidebar,
    general,
    payments,
    regions,
    invoices
  }
  // ,plugins: [
  //   createPersistedState({
  //     key: "vuex",
  //     paths: ["auth"],
  //     reducer(val) {
  //       const x = getToken();
  //       if (!x || x === null) {
  //         return {};
  //       }
  //       return val;
  //     }
  //   })
  // ]
});
