const ID_TOKEN_KEY = `${process.env.VUE_APP_COMPONENTS_PREFIX}_id_token`;
const ID_USER_KEY = `${process.env.VUE_APP_COMPONENTS_PREFIX}_id_user`;
const ID_STORE_KEY = `${process.env.VUE_APP_COMPONENTS_PREFIX}_id_store`;
const ID_USER_NAME = `${process.env.VUE_APP_COMPONENTS_PREFIX}_name_user`;
const ID_USER_AVATAR = `${process.env.VUE_APP_COMPONENTS_PREFIX}_avatar_user`;
const ID_REGION = `${process.env.VUE_APP_COMPONENTS_PREFIX}_REGION`;
const DEVICE_TOKEN = `${process.env.VUE_APP_COMPONENTS_PREFIX}_DEVICE_TOKEN`;
const BRANCHES = `${process.env.VUE_APP_COMPONENTS_PREFIX}_BRANCHES`;
export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};
export const saveToken = (
  token,
  { region = "pk", id = null, avatar = false, name = "", storeId = null }
) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  window.localStorage.setItem(ID_USER_KEY, id);
  window.localStorage.setItem(ID_STORE_KEY, storeId);
  window.localStorage.setItem(ID_USER_NAME, name);
  window.localStorage.setItem(ID_USER_AVATAR, avatar);
  window.localStorage.setItem(ID_REGION, region);
};
export const getUserId = () => {
  return window.localStorage.getItem(ID_USER_KEY);
};
export const getRegion = () => {
  return window.localStorage.getItem(ID_REGION);
};
export const getStoreId = () => {
  return window.localStorage.getItem(ID_STORE_KEY);
};
export const getUserName = () => {
  return window.localStorage.getItem(ID_USER_NAME);
};
export const getUserAvatar = () => {
  return window.localStorage.getItem(ID_USER_AVATAR);
};
export const getDeviceToken = () => {
  return window.localStorage.getItem(DEVICE_TOKEN);
};
export const saveDeviceToken = deviceToken => {
  if (deviceToken === null) {
    return window.localStorage.removeItem(DEVICE_TOKEN);
  }
  return window.localStorage.setItem(DEVICE_TOKEN, deviceToken);
};
export const setBranches = branches => {
  return window.localStorage.setItem(BRANCHES, JSON.stringify(branches));
};
export const getBranches = () => {
  const xx = window.localStorage.getItem(BRANCHES);
  if (xx) {
    return JSON.parse(xx);
  }
  return null;
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_USER_KEY);
  window.localStorage.removeItem(ID_STORE_KEY);
  window.localStorage.removeItem(ID_USER_NAME);
  window.localStorage.removeItem(ID_USER_AVATAR);
  window.localStorage.removeItem(ID_REGION);
  window.localStorage.removeItem(BRANCHES);
};
export default {
  getToken,
  saveToken,
  destroyToken,
  getUserId,
  getUserName,
  getUserAvatar,
  getRegion,
  getStoreId,
  getDeviceToken,
  setBranches,
  getBranches
};
