/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
// import ApiService from "@/core/services/api.service";
import { getStoreId } from "@/core/services/jwt.service";
import ApiService from "../api.service";
// import getStoreId from "../jwt.service";
// action types
export const GET_INVOICES = "get_invoices";
// mutation types
export const SET_INVOICES = "set_invoices";
const state = {
  invoices: []
};
const getters = {};
const actions = {
  [GET_INVOICES]({ commit }) {
    return new Promise(resolve => {
      const storeId = getStoreId();
      ApiService.get(`/store/${storeId}/invoice`)
        .then(data => {
          console.log("promise resolved", data);
          commit(SET_INVOICES, data);
          //   console.log("promise resolved");
          resolve(data);
        })
        .catch(error => {
          resolve(error);
        });
    });
  }
};

const mutations = {
  [SET_INVOICES](currentState, invoices) {
    currentState.invoices = invoices;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
