import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/orders/pending",
      name: "Base Route",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/orders/:id",
          name: "Active Orders",
          meta: { color: "red" },
          component: () => import("@/view/pages/Orders")
        },
        {
          path: "/order/:id",
          name: "pending-order",
          meta: {
            color: "red",
            requireAuth: true
          },
          component: () => import("@/view/sub-pages/orders/AcceptOrder")
        },
        {
          path: "/order/:id/products",
          name: "order-products",
          meta: { color: "red" },
          component: () => import("@/view/sub-pages/orders/ProductsSearch")
        },
        {
          path: "/payments/:id",
          name: "Payments",
          meta: { color: "red" },
          component: () => import("@/view/pages/Payments")
        },
        {
          path: "/payments/detail/:payment",
          name: "Payment Detail",
          meta: { color: "red" },
          component: () => import("@/view/sub-pages/payments/PaymentDetails")
        },
        {
          path: "/auth/forgotPassword",
          name: "Forgot Password",
          meta: { color: "red" },
          component: () => import("@/view/pages/ForgotPassword")
        },
        {
          path: "/email-recieved",
          name: "Email Recieved",
          meta: { color: "red" },
          component: () => import("@/view/emails/orderRecieved")
        },
        {
          path: "/invoices/:id",
          name: "Invoices",
          meta: { color: "red" },
          component: () => import("@/view/pages/Invoices")
        },
        {
          path: "/performance",
          name: "Dashboard",
          meta: { color: "red" },
          component: () => import("@/view/pages/Performance")
        },
        {
          path: "/my-profile",
          name: "My Profile",
          meta: { color: "red" },
          component: () => import("@/view/pages/Profile")
        },

        {
          /* Revenue */
          path: "/revenue",
          name: "Revenue",
          meta: { color: "red" },
          component: () => import("@/view/pages/Revenue")
        },
        {
          /* Order Status */
          path: "/order-status/:id",
          name: "Order Status",
          meta: { color: "red" },
          component: () => import("@/view/pages/OrderStatus")
        },
        {
          /* Orders History */
          path: "/order-history",
          name: "Order History",
          meta: { color: "red" },
          component: () => import("@/view/pages/OrderHistory")
        },
        {
          /* Notifications */
          path: "/notifications",
          name: "Notifications",
          meta: { color: "red" },
          component: () => import("@/view/pages/Notifications")
        },
        {
          /* Referrals */
          path: "/referrals",
          name: "Referrals",
          meta: { color: "red" },
          component: () => import("@/view/pages/Referrals")
        },
        {
          /* Tutorial */
          path: "/tutorial",
          name: "Tutorial",
          meta: { color: "red" },
          component: () => import("@/view/pages/Tutorial")
        },
        {
          /* Help Link */
          path: "/help",
          name: "Help",
          meta: { color: "red" },
          component: () => import("@/view/pages/Chat")
        },
        {
          /* Help Link */
          path: "/pdf",
          name: "Invoices",
          meta: { color: "red" },
          component: () => import("@/view/sub-pages/invoices/PdfInvoice")
        }
      ]
    },
    {
      /* Help Link */
      path: "/auth",
      name: "Auth",
      redirect: "/auth/login",
      component: () => import("@/view/layout/AuthLayout"),
      children: [
        {
          /* Help Link */
          path: "/auth/login",
          name: "Login",
          meta: { color: "red" },
          component: () => import("@/view/pages/Login")
        }
      ]
    }
  ]
});
export default router;
