/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import Vue from "vue";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
// action types
export const GET_VERFICATION_CODE = "getVerificationCode";
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const RESET_PASS = "resetPass";
export const ADD_PUSH_TOKEN = "addPushToken";
// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_PASSWORD = "setPassword";
export const SET_CODE = "setCode";
export const SET_PUSH_TOKEN = "setPushToken";
// state
const state = {
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  todos: [],
  password: "",
  code: "",
  token: "",
  pushToken: ""
};
// getters
const getters = {
  currentUser(currentState) {
    return currentState.user;
  },
  isAuthenticated(currentState) {
    return currentState.isAuthenticated;
  },
  getVerificationCode(currentState) {
    return currentState.code;
  },
  getPushToken(currentState) {
    return currentState.pushToken;
  }
};
// actions
const actions = {
  // eslint-disable-next-line no-unused-vars
  [GET_VERFICATION_CODE]({ commit }, { phone, password, login }) {
    const request = {
      phone,
      password,
      login,
      userType: "VENDOR"
    };
    commit(SET_PASSWORD, password);
    commit(SET_ERROR, "");
    return new Promise(resolve => {
      ApiService.post("/user/VerificationCode", request)
        .then(({ data: response }) => {
          commit(SET_CODE, response.code);
          if (response.success) {
            resolve(true);
          } else {
            resolve(false);
            const { error } = response;
            commit(SET_ERROR, error.message);
          }
        })
        .catch(({ data }) => {
          console.error("data", data);
          commit(SET_ERROR, "Error while Logging in.");
          resolve(false);
        });
    });
  },
  [LOGIN](context, credentials) {
    const { phone, region } = credentials;
    delete credentials.phone;
    credentials.userId = phone;
    credentials.userType = "VENDOR";
    return new Promise(resolve => {
      context.commit(SET_ERROR, "");
      ApiService.post("/user/login", credentials)
        .then(({ data }) => {
          if (data.success) {
            data.userRegion = region;
            context.commit(SET_ERROR, data.message);
            context.commit(SET_AUTH, data);
            resolve(true);
          } else {
            context.commit(SET_ERROR, data.message);
            resolve(false);
          }
        })
        .catch(data => {
          context.commit(SET_ERROR, data);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      const request = {
        userType: "VENDOR",
        storeId: JwtService.getStoreId() || null,
        deviceToken: Vue.prototype.$firebase.deviceToken
      };
      ApiService.post("/user/logout2", request).then(() => {
        context.commit(PURGE_AUTH);
        Vue.prototype.$firebase.deleteToken();
        resolve(true);
      });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    return new Promise(resolve => {
      if (JwtService.getToken()) {
        ApiService.get("/user/verifyToken")
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            resolve(true);
          })
          .catch(response => {
            console.log(
              "file: auth.module.js | line 139 | response ",
              response
            );
            context.commit(SET_ERROR, response.data.errors);
            context.commit(PURGE_AUTH);
            resolve(true);
          });
      } else {
        resolve(true);
        context.commit(PURGE_AUTH);
      }
    });
  },
  [RESET_PASS](context, credentials) {
    credentials.userType = "VENDOR";
    return new Promise(resolve => {
      context.commit(SET_ERROR, "");
      ApiService.post("/user/forgetPasswordRider", credentials)
        .then(({ data }) => {
          if (data.success) {
            context.commit(SET_ERROR, data.message);
            resolve(true);
          } else {
            context.commit(SET_ERROR, data.message);
            resolve(false);
          }
        })
        .catch(data => {
          context.commit(SET_ERROR, data);
        });
    });
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }
    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
  [ADD_PUSH_TOKEN](context, firebaseToken) {
    const body = {
      deviceToken: firebaseToken,
      storeId: JwtService.getStoreId()
    };
    return new Promise(resolve => {
      context.commit(SET_ERROR, "");
      ApiService.post("/store/addDeviceToken", body)
        .then(({ data }) => {
          if (data.success) {
            context.commit(SET_PUSH_TOKEN, firebaseToken);
            context.commit(SET_ERROR, data.message);
            resolve(true);
          } else {
            context.commit(SET_ERROR, data.message);
            resolve(false);
          }
        })
        .catch(data => {
          context.commit(SET_ERROR, data);
        });
    });
  }
};
// mutations
const mutations = {
  [SET_ERROR](currentState, error) {
    currentState.errors = error;
  },
  async [SET_AUTH](currentState, user) {
    currentState.isAuthenticated = true;
    currentState.user = user;
    ApiService.setHeader("Authorization", `Bearer ${user.token}`);
    JwtService.saveToken(user.token, {
      id: user.user._id,
      storeId: user.user.userDetail._id,
      name: user.user.name,
      avatar: user.user.userDetail.storeLogo,
      region: user.userRegion
    });
  },
  [PURGE_AUTH](currentState) {
    currentState.isAuthenticated = false;
    currentState.user = {};
    JwtService.destroyToken();
  },
  [SET_PASSWORD](currentState, pass) {
    currentState.password = pass;
  },
  [SET_CODE](currentState, code) {
    currentState.code = code;
  },
  [SET_PUSH_TOKEN](currentState, pushToken) {
    currentState.pushToken = pushToken;
  }
};
export default {
  state,
  actions,
  getters,
  mutations
};
