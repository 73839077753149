/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-mutable-exports */
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/messaging";
import { saveDeviceToken } from "../services/jwt.service";
// Initialize Firebase
const config = {
  apiKey: "AIzaSyDe3eyMnJjg4Im6xYmgn_Qsg4i70gvstzg",
  authDomain: "baskyt-customer-app.firebaseapp.com",
  databaseURL: "https://baskyt-customer-app.firebaseio.com",
  projectId: "baskyt-customer-app",
  storageBucket: "baskyt-customer-app.appspot.com",
  messagingSenderId: "186665767098",
  appId: "1:186665767098:web:af287df3efbae555daf283",
  measurementId: "G-NHLG864D9T"
};
class FirebaseService {
  fire = null;

  messaging = null;

  deviceToken = null;

  hasPermissions = false;

  workerChannel = null;

  soundChannel = null;

  constructor() {
    return this.init();
  }

  init() {
    saveDeviceToken(null);
    if (!this.fire) {
      try {
        this.fire = firebase.initializeApp(config);
        this.fire.firestore().settings({ timestampsInSnapshots: true });
      } catch (error) {
        console.error(
          "An error occurred while initlializing firebase => \n",
          error
        );
      }
    }
  }

  initMessaging() {
    if (!this.fire) {
      console.error("Error in initMessaging: No firebase initialized.");
    } else if (!this.messaging) {
      try {
        this.messaging = firebase.messaging();
      } catch (error) {
        console.error(
          "An error occurred while initlializing firebase messaging=>\n",
          error
        );
      }
    }
  }

  async getPushPermissions() {
    if (!this.messaging) {
      console.error("Error in getPushToken: No firebase messaging initialized");
    } else {
      try {
        await this.messaging.requestPermission();
        this.hasPermissions = true;
        return true;
      } catch (error) {
        console.log(
          "file: firestore.js | line 73 | getPushPermissions | error",
          error
        );
      }
    }
    return false;
  }

  async deleteToken() {
    if (!this.deviceToken) {
      console.warn("Warning deleteToken: No token present to delete.");
    } else {
      const deleted = await this.messaging.deleteToken();
      if (deleted) {
        this.deviceToken = null;
        saveDeviceToken(null);
      }
    }
  }

  async getPushToken() {
    if (!this.messaging) {
      console.error("Error in getPushToken: No firebase messaging initialized");
    } else if (!this.hasPermissions) {
      console.error("Push Permissions not granted.");
    } else {
      try {
        const token = await this.messaging.getToken();
        if (token) {
          this.deviceToken = token;
          saveDeviceToken(token);
          this.addListeners();
          return true;
        }
      } catch (error) {
        console.error(
          "An error occurred while initlializing firebase messaging deviceToken=>\n",
          error
        );
      }
    }
    return false;
  }

  addListeners() {
    console.log("Adding listeners");
    if (this.messaging && this.deviceToken && this.hasPermissions) {
      this.workerChannel = new BroadcastChannel("firebase-background-channel");
      this.soundChannel = new BroadcastChannel("firebase-sound-channel");
      this.messaging.onMessage(msg => {
        this.workerChannel.postMessage(msg);
        this.playSound();
      });
      this.soundChannel.onmessage = () => {
        this.playSound();
      };
    }
  }

  playSound() {
    // Need to work here
  }
}
const fire = new FirebaseService();
export default fire;
