<template>
  <div>
    <!-- Only renders when the device is online -->
    <router-view> </router-view>
    <template v-if="prompt">
      <BaskytModal ref="updateModal" :hideClose="true">
        <template v-slot:header> Update! {{ appVersion }} </template>
        <template v-slot:body>
          A new version is found. Refresh to load it!
          <ul class="update-list">
            <li class="update-list__item">Fixed loading bug with branches</li>
          </ul>
        </template>
        <template v-slot:footer>
          <button
            class="update-dialog__button update-dialog__button--confirm"
            @click="update"
          >
            Update
          </button>
          <button
            class="update-dialog__button update-dialog__button--cancel"
            @click="cancelUpdate"
          >
            Cancel
          </button>
        </template>
      </BaskytModal>
    </template>
  </div>
</template>

<script>
/* eslint-disable import/no-unresolved */

import { mapState } from "vuex";
import { GET_INSTALL_STATUS } from "@/core/services/store/general.module";
import BaskytModal from "@/view/components/common/BaskytModal";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}app`,
  components: {
    BaskytModal
  },
  methods: {
    async update() {
      if (this.$workbox) {
        await this.$workbox.messageSW({ type: "SKIP_WAITING" });
        this.$refs.updateModal.hide();
        setTimeout(() => {
          this.prompt = false;
        }, 100);
      }
    },
    cancelUpdate() {
      this.$refs.updateModal.hide();
      setTimeout(() => {
        this.prompt = false;
      }, 100);
    }
  },
  data() {
    return {
      prompt: false
    };
  },

  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.prompt = true;
      });
    }
    this.$store.dispatch(GET_INSTALL_STATUS, null);
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      this.$store.dispatch(GET_INSTALL_STATUS, e);
    });
  },
  computed: {
    ...mapState({
      appVersion: ({ general }) => general.appVersion
    })
  },

  watch: {
    prompt(val) {
      if (val !== false)
        setTimeout(() => {
          this.$refs.updateModal.show();
        }, 100);
    }
  }
};
</script>

<style lang="scss">
@import "assets/sass/style.vue";
body {
  font-family: "Nunito", sans-serif;
}
.update-dialog {
  font-family: "Nunito", sans-serif;
  &__button {
    padding: 5px 15px;
    margin-right: 8px;
    border: 0;
    border-radius: 5px;
    outline: none;

    &--confirm {
      margin-left: auto;
    }
  }
}
</style>
