<template>
  <div>
    <b-modal
      ref="baskyt-modal"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      centered
      modal-class="baskyt-modal"
    >
      <div
        class="baskyt-modal__close"
        v-if="
          hideClose === undefined || hideClose === false || hideClose === null
        "
      >
        <button class="baskyt-modal__close__btn" @click="hide">
          <i class="fa fa-close"></i>
        </button>
      </div>
      <div class="baskyt-modal__header">
        <slot name="header"></slot>
      </div>
      <div class="baskyt-modal__body">
        <slot name="body"></slot>
      </div>
      <div class="baskyt-modal__footer">
        <slot name="footer"></slot>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}modal`,
  props: {
    hideClose: Boolean
  },
  methods: {
    show() {
      this.$refs["baskyt-modal"].show();
    },
    hide() {
      this.$refs["baskyt-modal"].hide();
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped></style>
