/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import ApiService from "@/core/services/api.service";
import { getStoreId } from "@/core/services/jwt.service";

// action types
export const GET_PENDING_PAYMENTS = "get_pending_payments";
export const GET_PAID_PAYMENTS = "get_paid_payments";
export const RECEIVE_PAYMENT = "receive_payments";
// mutation types
export const SET_PENDING_PAYMENTS = "set_pending_payments";
export const SET_PAID_PAYMENTS = "set_paid_payments";
export const SET_PENDING_PAYMENTS_FETCHING = "set_pending_payments_fetching";
export const SET_PAID_PAYMENTS_FETCHING = "set_paid_payments_fetching";
export const SET_PAYMENT_ACTION = "set_payment_action";

const state = {
  pendingPayments: [],
  pendingPaymentsFetching: false,
  pendingPaymentsCount: 0,
  paidPayments: [],
  paidPaymentsFetching: false,
  paidPaymentsCount: 0,
  paymentAction: false
};
const getters = {
  getAPendingPayment: currentState => id =>
    currentState.pendingPayments.find(p => p._id === id),
  getAPaidPayment: currentState => id =>
    currentState.paidPayments.find(p => p._id === id),
  getPendingPayments: currentState => currentState.pendingPayments,
  getPaidPayments: currentState => currentState.paidPayments
};
const actions = {
  [GET_PENDING_PAYMENTS]({ commit }) {
    return new Promise(resolve => {
      const storeId = getStoreId();

      ApiService.get(`/store/${storeId}/verifiedPayments?status=pending`)
        .then(({ data }) => {
          commit(SET_PENDING_PAYMENTS, data.payments);
          resolve(data);
        })
        .catch(error => {
          resolve(error);
        });
    });
  },
  [GET_PAID_PAYMENTS]({ commit }) {
    return new Promise(resolve => {
      const storeId = getStoreId();
      ApiService.get(`/store/${storeId}/verifiedPayments?status=verified`)
        .then(({ data }) => {
          commit(SET_PAID_PAYMENTS, data.payments);
          resolve(data);
        })
        .catch(error => {
          resolve(error);
        });
    });
  },
  [RECEIVE_PAYMENT]({ commit }, payload) {
    commit(SET_PAYMENT_ACTION, true);
    return new Promise(resolve => {
      const storeId = getStoreId();
      payload.store = storeId;
      ApiService.post(`/store/verifyPayments`, payload)
        .then(({ data }) => {
          commit(SET_PAYMENT_ACTION, false);
          resolve(data);
        })
        .catch(error => {
          resolve(error);
        });
    });
  }
};

const mutations = {
  [SET_PENDING_PAYMENTS](currentState, payments) {
    currentState.pendingPayments = payments;
  },
  [SET_PAID_PAYMENTS](currentState, payments) {
    currentState.paidPayments = payments;
  },
  [SET_PENDING_PAYMENTS_FETCHING](currentState, fetchState) {
    currentState.pendingPaymentsFetching = fetchState;
  },
  [SET_PAID_PAYMENTS_FETCHING](currentState, fetchState) {
    currentState.paidPaymentsFetching = fetchState;
  },
  [SET_PAYMENT_ACTION](currentState, s) {
    currentState.paymentAction = s;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
