/* eslint-disable no-param-reassign */
// ACTION TYPES
export const GET_INSTALL_STATUS = "get_pwa_install_status";
export const SET_INSTALL_STATUS = "set_pwa_install_status";
export const UPDATE_APP_VERSION = "get_app_version";
export const SET_APP_VERSION = "set_app_version";

const state = {
  installPrompt: null,
  appVersion: process.env.PACKAGE_VERSION || "0"
};
const getters = {
  getInstallPrompt(currentState) {
    return currentState.installPrompt;
  },
  appVersion(curretState) {
    return curretState.appVersion;
  }
};
const actions = {
  [GET_INSTALL_STATUS](context, prompt) {
    return new Promise(resolve => {
      context.commit(SET_INSTALL_STATUS, prompt);
      resolve(prompt);
    });
  },
  [UPDATE_APP_VERSION]({ commit }) {
    commit(SET_APP_VERSION, process.env.PACKAGE_VERSION);
  }
};
const mutations = {
  [SET_INSTALL_STATUS](currentState, prompt) {
    currentState.installPrompt = prompt;
  },
  [SET_APP_VERSION](currentState, value) {
    currentState.appVersion = value;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
