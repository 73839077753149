// import Vue from "vue";
import axios from "axios";
import { getToken } from "./jwt.service";
/**
 * Service to call HTTP request via Axios
 */
const Service = {
  isntance: null,
  init(US = false) {
    if (!this.instance) {
      this.instance = axios.create({ withCredentials: true });
      const local = US
        ? process.env.VUE_APP_API_URL_US
        : process.env.VUE_APP_API_URL_PK;
      this.instance.defaults.baseURL = local;
      this.instance.defaults.headers["content-type"] = "Application/JSON";
      if (getToken()) {
        this.setHeader("Authorization", `Bearer ${getToken()}`);
      }
    }
  },
  /**
   * Set the default HTTP request headers
   */
  setHeader(header, val) {
    this.instance.defaults.headers[header] = val;
  },
  query(resource, params) {
    if (!this.isntance) {
      this.init();
    }
    return this.instance.get(resource, params).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  },
  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    if (!this.isntance) {
      this.init();
    }
    return new Promise((resolve, reject) => {
      this.instance
        .get(`${resource}/${slug}`)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          const err = {
            data: {
              success: 0,
              message: error.message,
              url: this.instance.defaults.baseURL + resource
            }
          };
          reject(err);
        });
    });
  },
  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, passedParams = {}) {
    if (!this.isntance) {
      this.init();
    }
    const params = passedParams;
    return new Promise((resolve, reject) => {
      this.instance
        .post(`${resource}`, params)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          const err = {
            data: {
              success: 0,
              message: error.message,
              url: this.instance.defaults.baseURL + resource
            }
          };
          reject(err);
        });
    });
  },
  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    if (!this.isntance) {
      this.init();
    }
    return this.instance.put(`${resource}/${slug}`, params);
  },
  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    if (!this.isntance) {
      this.init();
    }
    return this.instance.put(`${resource}`, params);
  },
  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    if (!this.isntance) {
      this.init();
    }
    return this.instance.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};
if (!window.ApiService) {
  window.ApiService = Service;
  // window.ApiService.init();
}
export default window.ApiService;
