/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import ApiService from "@/core/services/api.service";
import { getStoreId, setBranches, getBranches } from "../jwt.service";
// ACTION TYPES
export const GET_ORDER_STATUS_ACTIVE = "get_order_status_active";
export const GET_ORDER_STATUS_PENDING = "get_order_status_pending";
export const GET_ORDER_STATUS_DELIVERED = "get_order_status_delivered";
export const ACCEPT_ORDER = "accept_an_order";
export const FIND_RIDER = "find_rider_for_order";
export const FIND_PRODUCTS = "find_products_in_order";
export const GET_BRANCHES = "get_store_branches";
// MUTATION TYPES
const SET_ORDER_STATUS_ACTIVE = "set_order_status_active";
const SET_SQUAD_STATUS_ACTIVE = "set_squad_status_active";
const SET_SQUAD_STATUS_DELIVERED = "set_squad_status_delivered";
const SET_ORDER_STATUS_PENDING = "set_order_status_pending";
const SET_SQUAD_STATUS_PENDING = "set_squad_status_pending";
const SET_ORDER_STATUS_DELIVERED = "set_order_status_delivered";
const SET_ACCEPTED_ORDER = "set_accepted_order";
const SET_RIDERS_FINDING = "set_rider_finding";
const TOGGLE_FINDING_DELIVERED = "toggle_finding_delivered";
const TOGGLE_FINDING_ACTIVE = "toggle_finding_active";
const TOGGLE_FINDING_PENDING = "toggle_finding_pending";
// STATE
const state = {
  fetchingActive: false,
  orderStatusActive: [],
  fetchingPending: false,
  orderStatusPending: [],
  squadStatusPending: [],
  squadStatusActive: [],
  squadStatusDelivered: [],
  fetchingDelivered: false,
  orderStatusDelivered: [],
  nonRiderOrders: []
};
// GETTERS
const getters = {
  getNonRiderOrders: currentState => currentState.nonRiderOrders,
  getPendingOrderStatus: currentState => currentState.orderStatusPending,
  getActiveOrderStatus: currentState => currentState.orderStatusActive,
  getDeliveredOrderStatus: currentState => currentState.orderStatusDelivered,
  getPendingOrder: currentState => id =>
    currentState.orderStatusPending.find(o => o.orderId === id),
  getActiveOrder: currentState => id =>
    currentState.orderStatusActive.find(o => o.orderId === id),
  getDeliveredOrder: currentState => id =>
    currentState.orderStatusDelivered.find(o => o.orderId === id),
  getActiveSquadOrder: currentState => id =>
    currentState.squadStatusActive.find(o => o.inviteId === id),
  getDeliveredSquadOrder: currentState => id =>
    currentState.squadStatusDelivered.find(o => o.inviteId === id),
  getPendingSquadOrder: currentState => id =>
    currentState.squadStatusPending.find(o => o.inviteId === id),
  isFetchingActive: currentState => currentState.fetchingActive,
  isFetchingPending: currentState => currentState.fetchingPending,
  isFetchingDelivered: currentState => currentState.fetchingDelivered
};
// ACTIONS
const actions = {
  [GET_BRANCHES]() {
    return new Promise(resolve => {
      if (getBranches()) {
        resolve(getBranches());
      } else {
        ApiService.get("/store/getStoreBranches")
          .then(({ data: response }) => {
            if (response.success) {
              const { nBranches, branches } = response;
              setBranches({ nBranches, branches });
              resolve(getBranches());
            } else {
              resolve(null);
            }
          })
          .catch(error => {
            console.error(
              "file: orders.module.js | line 58 | .then | error",
              error
            );
            resolve(null);
          });
      }
    });
  },
  [FIND_PRODUCTS](context, payload = {}) {
    return new Promise(resolve => {
      ApiService.post("/store/productsFound", payload)
        .then(({ data: response }) => {
          if (response.success) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(({ data: response }) => {
          console.error(response);
          resolve(false);
        });
    });
  },
  [ACCEPT_ORDER]({ commit, getters: gets }, { id, squad }) {
    // eslint-disable-next-line no-underscore-dangle
    let orderId = null;
    let squadId = null;
    if (!squad) {
      orderId = gets.getPendingOrder(id)._id;
    } else {
      squadId = id;
    }

    const request = {
      orderId,
      storeId: getStoreId(),
      squadId
    };
    return new Promise((resolve, reject) => {
      ApiService.post("/store/acceptLocalOrder", request)
        .then(({ data: response }) => {
          if (response.success === 1) {
            if (response.order) commit(SET_ACCEPTED_ORDER, response.order);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(({ data: response }) => {
          reject(response);
        });
    });
  },
  [GET_ORDER_STATUS_ACTIVE]({ commit }) {
    commit(TOGGLE_FINDING_ACTIVE, true);
    const request = {
      id: getStoreId(),
      type: "Accepted"
    };
    return new Promise(resolve => {
      ApiService.post("/store/getOrderStatus", request)
        .then(({ data: response }) => {
          const { orders, squads } = response;
          commit(SET_SQUAD_STATUS_ACTIVE, squads);
          commit(SET_ORDER_STATUS_ACTIVE, orders);
          if (orders.length > 0) {
            const temp = orders.filter(o => o.rider === null);
            commit(SET_RIDERS_FINDING, temp);
          } else {
            commit(SET_RIDERS_FINDING, []);
          }
          commit(TOGGLE_FINDING_ACTIVE, false);
          resolve(true);
        })
        .catch(error => {
          commit(TOGGLE_FINDING_ACTIVE, false);
          console.error(error);
          resolve(false);
        });
    });
  },
  [GET_ORDER_STATUS_PENDING]({ commit }) {
    commit(TOGGLE_FINDING_PENDING, true);
    const request = {
      id: getStoreId(),
      type: "Pending"
    };
    return new Promise(resolve => {
      ApiService.post("/store/getOrderStatus", request)
        .then(({ data: response }) => {
          const { orders, squads } = response;
          commit(SET_ORDER_STATUS_PENDING, orders);
          commit(SET_SQUAD_STATUS_PENDING, squads);
          commit(TOGGLE_FINDING_PENDING, false);
          resolve(true);
        })
        .catch(error => {
          commit(TOGGLE_FINDING_PENDING, false);
          console.error(error);
          resolve(false);
        });
    });
  },
  [GET_ORDER_STATUS_DELIVERED]({ commit }) {
    commit(TOGGLE_FINDING_DELIVERED, true);
    const request = {
      id: getStoreId(),
      delivered: true
    };
    return new Promise(resolve => {
      ApiService.post("/store/getOrderStatus", request)
        .then(({ data: response }) => {
          const { orders } = response;
          commit(SET_ORDER_STATUS_DELIVERED, orders);
          commit(TOGGLE_FINDING_DELIVERED, false);
          resolve(true);
        })
        .catch(error => {
          commit(TOGGLE_FINDING_DELIVERED, false);
          console.error(error);
          resolve(false);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  [FIND_RIDER]({ commit, getters: gets }) {
    return new Promise(resolve => {
      const emptyOrders = gets.getNonRiderOrders;
      if (emptyOrders.length > 0) {
        Promise.all(
          emptyOrders.map(o => {
            const { address: point } = o.stores[0];
            const orderId = o._id;
            const request = {
              point,
              orderId
            };
            return ApiService.post("/order/findLocalRider", request)
              .then(res => res)
              .catch(err => err);
          })
        )
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            console.error(error);
            resolve(true);
          });
      } else {
        resolve(true);
      }
    });
  }
};
// MUTATIONS
const mutations = {
  [SET_ORDER_STATUS_ACTIVE](currentState, orders) {
    currentState.orderStatusActive = orders;
  },
  [SET_SQUAD_STATUS_ACTIVE](currentState, orders) {
    currentState.squadStatusActive = orders;
  },
  [SET_ORDER_STATUS_PENDING](currentState, orders) {
    currentState.orderStatusPending = orders;
  },
  [SET_SQUAD_STATUS_PENDING](currentState, squads) {
    currentState.squadStatusPending = squads;
  },
  [SET_ORDER_STATUS_DELIVERED](currentState, orders) {
    currentState.orderStatusDelivered = orders;
  },
  [SET_ACCEPTED_ORDER](currentState, order) {
    const { orderStatusActive, orderStatusPending } = currentState;
    const index = orderStatusPending
      // eslint-disable-next-line no-underscore-dangle
      .map(o => o._id.toString())
      // eslint-disable-next-line no-underscore-dangle
      .indexOf(order._id.toString());
    currentState.orderStatusPending = orderStatusPending.filter(
      (o, i) => i !== index
    );
    orderStatusActive.push(order);
  },
  [SET_RIDERS_FINDING](currentState, orders) {
    currentState.nonRiderOrders = orders;
  },
  [TOGGLE_FINDING_DELIVERED](currentState, f) {
    currentState.fetchingDelivered = f;
  },
  [TOGGLE_FINDING_ACTIVE](currentState, f) {
    currentState.fetchingActive = f;
  },
  [TOGGLE_FINDING_PENDING](currentState, f) {
    currentState.fetchingPending = f;
  }
};
export default {
  actions,
  state,
  mutations,
  getters
};
