/* eslint-disable no-param-reassign */
// Action Types
export const GET_REGION = "get_region";
// Mutation Types
const SET_REGION = "set_region";
const SET_REGION_ERROR = "set_region_error";
// State
const state = {
  region: "",
  regionError: "",
  stage: process.env.VUE_APP_STAGE
};
// Getters
const getters = {
  getregion: currentState => currentState.region
};
// Actions
const actions = {
  [GET_REGION](context) {
    return new Promise(resolve => {
      fetch(`https://api.ipregistry.co/?key=${process.env.VUE_APP_IP_KEY}`)
        .then(response => response.json())
        .then(data => {
          const region = data.location.country.code;
          if (region === "US") {
            context.commit(SET_REGION, true);
          } else {
            context.commit(SET_REGION, false);
          }
          resolve(true);
        })
        .catch(error => {
          context.commit(SET_REGION_ERROR, error);
          resolve(false);
        });
    });
  }
};
// Mutations
const mutations = {
  [SET_REGION](currentState, isUS = false) {
    /* Reset this from prod */
    currentState.region = isUS;
    window.ApiService.init(isUS);
  },
  [SET_REGION_ERROR](currentState, error) {
    currentState.regionError = error;
  }
};
export default {
  actions,
  state,
  mutations,
  getters
};
